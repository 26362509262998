import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { gintoHeaderFontFamily } from '../src/shared-components/theme'
import { useTranslation } from 'react-i18next'
import HomeHeader from '../src/components/Index/HomeHeader'
import { useDownloadApp } from 'shared-components/utils/downloadApp'
import Typography from '@material-ui/core/Typography'
import { Button, useMediaQuery } from '@material-ui/core'
import HomeFloatingImage from '../src/components/Home/HomeFloatingImage'
import TextLogo from '../src/svg/TextLogo'
import { useTheme } from '@material-ui/core/styles'
import HomeFooter from '../src/components/Index/HomeFooter'
import Link from 'next/link'

export const landingMobileBreakpoint = 950

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      'radial-gradient(325.48% 133.59% at 90.78% 106.02%, rgba(251, 255, 238, 0.9) 7.38%, rgba(222, 229, 247, 0.675) 32.03%, rgba(244, 239, 251, 0.738) 58.85%, rgba(251, 255, 238, 0.9) 100%)',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullVh: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
  main: {
    position: 'absolute',
    top: '45%',
    left: 0,
    right: 0,
    margin: 'auto',
    transform: 'translateY(-50%)',
    maxWidth: 1120,
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      position: 'relative',
      top: 0,
      transform: 'none',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxWidth: '100vw',
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      alignItems: 'center',
      textAlign: 'center',
      minWidth: 0,
    },
  },
  header: {
    fontFamily: gintoHeaderFontFamily,
    fontWeight: 800,
    fontSize: '3.25rem',
    lineHeight: '2.925rem',
    letterSpacing: '-2%',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '2rem',
      lineHeight: '1.75rem',
    },
  },
  headerSecondary: {
    color: theme.palette.secondary.main,
  },
  caption: {
    fontWeight: 400,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  appButton: {
    borderRadius: '100px',
    padding: theme.spacing(2, 3),
    color: 'white',
    background: theme.palette.secondary[800],
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  createButton: {
    borderRadius: '100px',
    padding: theme.spacing(2, 3),
    color: theme.palette.secondary[800],
    background: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.wizard,
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 565,
    gap: 20,
    flexShrink: 1,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      marginTop: 96,
      gap: 10,
    },
  },
  image1: {
    width: '57%',
    minWidth: 290,
  },
  image2: {
    top: 90,
    zIndex: 10,
    flex: '1 1 0',
  },
  image3: {
    flex: '1.15 1 0',
  },
  bottom2Images: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      gap: 10,
    },
  },
  wordLogo: {
    marginBottom: 48,
  },
}))

const NewIndex = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(500))

  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'home',
    usingHref: true,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage && window.localStorage.setItem('hasSeenBash', 'true')
    }
  }, [])

  return (
    <div className={classes.root}>
      <QRCodeModal />
      <div className={classes.fullVh}>
        <HomeHeader />

        {/*<div className={classes.mainContainer}>*/}
        <div className={classes.main}>
          <div className={classes.left}>
            {isSmall && (
              <TextLogo height={14} width={56} className={classes.wordLogo} />
            )}

            <Typography component='h1' className={classes.header}>
              WHERE <span className={classes.headerSecondary}>HYPE</span> STARTS
            </Typography>
            <Typography className={classes.caption} component='h4' variant='h6'>
              Find things to do, see who else is going, and join the fun IRL.
            </Typography>
            <div className={classes.buttons}>
              {downloadUrl !== null && downloadUrl !== undefined && (
                <Link href={downloadUrl} legacyBehavior>
                  <Button
                    onClick={onDownloadClick}
                    className={classes.appButton}
                  >
                    Get the app
                  </Button>
                </Link>
              )}
              {downloadUrl == null && (
                <Button onClick={onDownloadClick} className={classes.appButton}>
                  Get the app
                </Button>
              )}

              <Link href='/create' legacyBehavior>
                <Button className={classes.createButton}>
                  Create an event
                </Button>
              </Link>
            </div>
          </div>

          <div className={classes.right}>
            <HomeFloatingImage className={classes.image1} variant={1} />
            <div className={classes.bottom2Images}>
              <HomeFloatingImage className={classes.image2} variant={3} />
              <HomeFloatingImage className={classes.image3} variant={2} />
            </div>
          </div>
        </div>
        {/*</div>*/}
      </div>
      <HomeFooter />
    </div>
  )
}

// Var used to disable some stuff applied to all pages through _app.js
NewIndex.trimApp = true

export default NewIndex
